<template>
  <div>
    <div class="shopAuthority">
      <h4 class="shopTitle">商户信息</h4>
      <div class="shopContent">
        <el-row>
          <el-col :span="4">企业名称：{{ mallInfo.companyName }}</el-col>
          <el-col :span="6" :offset="3">姓名：{{ mallInfo.name }}</el-col>
          <el-col :span="6" :offset="3">店铺名称：{{ mallInfo.shopName }}</el-col>
        </el-row>
        <el-row>
          <el-col :span="4">部门：{{ mallInfo.comment }}</el-col>
          <el-col :span="6" :offset="3">电话：{{ mallInfo.mobile }}</el-col>
        </el-row>
        <el-row>
          <el-col :span="4">类型： {{ mallInfo.manager }}</el-col>
          <el-col :span="10" :offset="3">性别：{{ mallInfo.sex }}</el-col>
        </el-row>
      </div>

      <div class="cutOff"></div>
      <div style="padding: 20px;">
        <el-tabs v-model="activeName">
          <el-tab-pane label="菜单授权" name="menu">
            <div class="shopContent authorityContent">
              <span>设置角色对应的功能权限、应用管理</span>
              <AuthMenu value-key="name" :menuGroups.sync="cities"></AuthMenu>
              <AuthMenu value-key="name" :menuGroups.sync="dynamicGroups" child-name="basics" checkbox-key="code"
                group-key="groupCode"></AuthMenu>
            </div>
            <div class="operation">
              <div class="btn">
                <el-button @click="cancel">取消</el-button>
                <el-button type="primary" @click="submit">提交</el-button>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="角色授权" name="role" v-if="isShowRole">
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAllRole"
              @change="handleCheckAllChange">全选</el-checkbox>
            <div style="margin: 15px 0;"></div>
            <el-checkbox-group v-model="checkedRoles" @change="handleCheckedRolesChange">
              <el-checkbox v-for="role in roles" :label="role.id" :key="role.id">{{ role.name }}</el-checkbox>
            </el-checkbox-group>
            <div class="operation">
              <div class="btn">
                <el-button @click="cancel">取消</el-button>
                <el-button type="primary" @click="submitRole">提交</el-button>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>

    </div>
  </div>
</template>

<script>
import AuthMenu from "./components/AuthMenu";
export default {
  components: { AuthMenu },
  data () {
    return {
      checkAllRole: false,
      checkedRoles: [],
      roles: [],
      isIndeterminate: false,
      isShowRole: false,
      userInfo: null,
      activeName: 'menu',
      checkAll: false,
      mallInfo: '',
      cities: [],
      fullCities: [],
      user_id: null,
      dynamicGroups: []
    }
  },
  async created () {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.user_id = this.$route.query.user_id
    await this.getAllModuleList(this.user_id)
    this.getDynamicModule(this.user_id)
    await this.getShopInfo(this.user_id)
    if (this.userInfo.type == 3) {
      this.isShowRole = true
      this.getMerchantRole(this.user_id)
    }
  },

  methods: {
    submitRole () {
      let params = {
        userId: this.user_id,
        roleIds: this.checkedRoles
      }
      this.$api.mallApi.merchantUpdateRoleApi(params).then(res => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.$router.go(-1)
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    handleCheckAllChange (val) {
      this.checkedRoles = val ? this.roles.map(item => item.id) : [];
      this.isIndeterminate = false;
    },
    handleCheckedRolesChange (value) {
      let checkedCount = value.length;
      this.checkAllRole = checkedCount === this.roles.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.roles.length;
    },
    getMerchantRole (userId) {
      let params = {
        userId
      }
      this.$api.mallApi.merchantRoleApi(params).then(res => {
        if (res.code == 200) {
          this.roles = res.data
          let checkedRoles = res.data.filter(item => item.hasRole).map(item => item.id)
          this.checkedRoles = checkedRoles
          this.handleCheckedRolesChange(checkedRoles)
        }
      })
    },
    /**
     * 获取详情
     */
    getShopInfo (id) {
      let params = {
        id
      }
      this.$api.mallApi.getCompanyApi(params)
        .then(({ code, data, msg }) => {
          if (code === 200) {
            this.mallInfo = {
              ...data,
              sex: data.sex === 'F' ? '女' : '男',
              manager: data.manager === 1 ? '管理员' : '员工'
            }
            if (data.module && data.module.length === this.cities.length) {
              this.checkAll = true
            }
          } else {
            this.$message.error(msg);
          }
        })
    },
    /**
     * 获取权限列表
     */
    getAllModuleList (userId) {
      let api = this.userInfo.type == 3 ? 'getAssistantList' : 'getAllModuleList'
      this.$api.shopapi[api]({ userId })
        .then(({ code, msg, data }) => {
          if (code === 200) {
            this.fullCities = data;
            this.cities = this.fullCities[0].child[0].child[0].child
          }
        })
    },

    getDynamicModule (userId) {
      this.$api.shopapi.dynamicModule('get', { userId }).then(({ code, data }) => {
        data && (this.dynamicGroups = (data.groups || []).map(e => (e.name = e.groupName, e)));
      })
    },

    /**
     * 提交
     */
    async submit () {
      const { code, data, msg } = await this.$api.shopapi.dynamicModule('put', { userId: this.user_id, groups: this.dynamicGroups.map(e => { delete e.isIndeterminate; return e }) })
      if (code !== 200) return this.$message.error(msg);
      const obj = {
        userId: +this.user_id,
        groups: this.update(this.fullCities)
      }
      this.$api.shopapi.updateModule(obj)
        .then(({ code, data, msg }) => {
          if (code === 200) {
            this.$message.success(msg);
            this.$router.go(-1)
          } else {
            this.$message.error(msg);
          }
        })
    },

    /**
     * 取消
     */
    cancel () {
      this.$router.push({ path: '/mallManage/mall/mainMall' })
    },

    update (arr) {
      return arr.map(ele => {
        if (ele.child && ele.child.length) {
          ele.child = this.update(ele.child)
        }
        ele.hasModule = ele.child.length ? ele.child.some(ch => ch.hasModule) : ele.hasModule
        return ele
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.shopAuthority {
  background-color: #fff;
}

.shopTitle {
  width: 100%;
  height: 55px;
  line-height: 55px;
  padding-left: 32px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(233, 233, 233, 1);
}

.shopContent {
  padding-left: 32px;
  margin-top: 20px;
  font-size: 14px;

  .el-row {
    margin-top: 30px;
  }
}

.cutOff {
  background-color: rgba(242, 242, 242, 1);
  width: 100%;
  height: 10px;
  margin-top: 20px;
}

.authorityContent {
  // flex: 1;
  // overflow-y: auto;
  padding: 0 60px 10px 30px;

  span {
    display: block;
    color: #CCCCCC;
    font-size: 14px;
  }
}

.operation {
  box-sizing: border-box;
  bottom: 0;
  width: 100%;
  height: 56px;
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row-reverse;

  .btn {
    align-items: center;
    line-height: 56px;
    margin-right: 30px;
  }
}
</style>
