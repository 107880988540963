<template>
  <div class="auth-module">
    <div class="dynamic-group" v-for="(group,groupIndex) in _menuGroups" :key="groupIndex">
      <el-checkbox class="checkAll" :indeterminate="group.isIndeterminate" :label="group[groupKey]" :key="group[groupKey]" v-model="group.hasModule"
                   @change="(val)=>selectAll(val,groupIndex)">{{group[valueKey]}}</el-checkbox>
      <div class="el-checkbox-group">
        <el-checkbox v-for="itr in group[childName]" v-model="itr.hasModule" :label="itr[checkboxKey]" :key="itr[checkboxKey]">{{itr[valueKey]}}</el-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthMenu",
  props: {
    valueKey:{
      type:String,
      default(){
        return 'name'
      }
    },
    menuGroups:{
      type:Array,
      default(){
        return []
      }
    },
    childName:{
      type:String,
      default(){
        return 'child'
      }
    },

    checkboxKey:{
      type:String,
      default(){
        return 'moduleId'
      }
    },

    groupKey:{
      type:String,
      default(){
        return 'moduleId'
      }
    },

  },

  computed:{
    _menuGroups:{
      get(){
        return this.menuGroups
      },
      set(val){
        this.$emit("update:menuGroups", val);
      }
    }
  },

  methods:{
    selectAll(val,groupIndex){
      this._menuGroups[groupIndex][this.childName] = (this._menuGroups[groupIndex][this.childName]||[]).map(e=>{e.hasModule = val;return e})
    },

    selectAllGroup(val){
      const checkFun = (menu)=>{
        return menu.map(e=>{
          if(e[this.childName]&&e[this.childName].length){
            e[this.childName] = checkFun(e[this.childName])
            this.$set(e,'isIndeterminate',val)
          }
          e.hasModule = val;
          return e
        })
      }
      this._menuGroups = checkFun(this._menuGroups)
    },

    setProp(val){
      return val.map(ele=>{
        ele.hasModule = ele[this.childName].some(e=>e.hasModule)
        let selected = ele[this.childName].filter(e=>e.hasModule)
        this.$set(ele,'isIndeterminate',selected.length>0 && selected.length<ele[this.childName].length)
        return ele;
      })
    }
  },

  watch:{
    '_menuGroups':{
      deep:true,
      handler(val){
        val = this.setProp(val)
      }
    },
  }
}
</script>

<style scoped lang="scss">
.el-checkbox-group{
  margin-left: 30px;
  margin-bottom: 10px;
  .el-checkbox{
    width: 22%;
  }
}
</style>