<template>
  <div class="mainShop" v-if="$route.path === '/mallManage/mall/mainMall'">
    <d-search :searchData="searchData" @searchEvent="searchEvent" @resetEvent="resetEvent" />
    <div class="content">
      <div class="header">
        <div>
          <el-button size="medium" type="primary" @click="exportShop" style="float: left;">{{ userInfo.type == 3 ?
    '导入员工' : '导入企业' }}</el-button>
        </div>
        <div class="operation">
          <el-button size="medium" type="primary" @click="addShopStaff">新建</el-button>
        </div>
      </div>
      <d-lable :columnData="columnData" :tableData="tableData" row-key="id" @compile="compile" @accredit="accredit"
        @positionChange="positionChange" @removeEvent="removeEvent"></d-lable>
    </div>
    <d-paging :pager="pager" @change="getItemList" />

    <el-dialog :title="dialogTitle" :visible.sync="outerVisible" custom-class="mainShopDialog">
      <el-dialog title="选择企业" :visible.sync="innerVisible" append-to-body :close-on-click-modal="false"
        custom-class="mainShopDialog">
        <p>选择楼层</p>
        <el-input v-model="filterText" placeholder="请输入企业名称" size="medium" style="width: 260px;margin-top: 10px;"
          @change="filterShop"></el-input>
        <el-tabs class="floor mainShopFloor" v-model="dialog.activeName">
          <el-tab-pane v-for="(item, index) in childList" :key="index" :label="item.name" :name="item.name">
            <div class="floorShop">
              <el-checkbox-group v-model="dialog.checked" @change="handleChange">
                <el-checkbox :label="shop.id" v-for="(shop, index) in item.childList" :key="index">{{ shop.name
                  }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div slot="footer" class="dialog-footer">
          <el-button @click="innerVisible = false">取 消</el-button>
          <el-button type="primary" @click="innerDialogConfirm">确定</el-button>
        </div>
      </el-dialog>
      <div class="details">
        <el-form ref="form" :model="dialog" :inline="true" :rules="rules" label-width="100px">
          <div class="form-item">
            <el-form-item label="姓名" prop="name">
              <el-input v-model="dialog.name" placeholder="请输入姓名" size="small"></el-input>
            </el-form-item>
            <el-form-item label="性别" prop="sex">
              <el-radio v-model="dialog.sex" label="M">男</el-radio>
              <el-radio v-model="dialog.sex" label="F">女</el-radio>
            </el-form-item>
          </div>
          <div class="form-item">
            <el-form-item label="手机号" prop="mobile">
              <el-input v-model="dialog.mobile" placeholder="请输入手机号" size="small"></el-input>
            </el-form-item>
            <el-form-item label="部门" prop="comment">
              <el-input v-model="dialog.comment" placeholder="请输入部门" size="small"></el-input>
            </el-form-item>
          </div>
          <div class="form-item" :style="{ pointerEvents: dialogTitle.includes('编辑') ? 'none' : '' }">
            <el-form-item label="所属企业" prop="shop" v-if="userInfo.type != 3">
              <el-select v-model="dialog.shop" :disabled="dialogTitle.includes('编辑')" placeholder="请选择企业"
                @click.native="selectShopEvent" no-data-text="" style="width: 200px" size="small">
                <el-option v-for="item in shopOptions" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属企业" v-if="company && userInfo.type == 3">
              <div style="width: 200px">{{ company.companyName }}</div>
            </el-form-item>
            <el-form-item label="所属店铺" v-if="company && userInfo.type == 3">
              {{ company.shopName }}
            </el-form-item>
          </div>
          <div class="form-item">
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="dialog.email" placeholder="请输入邮箱" size="small"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="outerVisible = false" size="medium">取 消</el-button>
        <el-button type="primary" size="medium" @click="confirm">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="批量导入" :visible.sync="uploadDialog" custom-class="mainShopDialog">
      <div class="details">
        <div class="upload-form">
          <div style="margin-right: 20px;">选择文件:</div>
          <el-input v-model="fileName" :disabled="true" size="small" style="width: 200px;margin-right: 10px;">
          </el-input>
          <el-upload
            action="#"
            ref="upload" :before-upload="beforeUpload" :on-change="handleChangeUpload" :http-request="uploadFile"
            :file-list="fileList" accept=".xls,.xlsx" :show-file-list="false" :auto-upload="false">
            <el-button slot="trigger" size="small" icon="el-icon-upload2" style="margin-top: 6px;">上传</el-button>
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>
        </div>
        <div class="dowload-file">
          <div style="margin-right: 10px;">请上传Excel文件</div>
          <el-button type="text" size="medium" @click="handleClickDowload">下载模板</el-button>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="uploadDialog = false" size="medium">关 闭</el-button>
        <el-button type="primary" size="medium" @click="submitUpload">确 定</el-button>
      </div>
    </el-dialog>
  </div>
  <router-view v-else />
</template>

<script>
import DSearch from '@/components/d-search'
import DLable from '@/components/d-table'
import DPaging from '@/components/d-paging'
import { mapActions, mapState } from 'vuex'
import { validatePhone } from "@/utils/validator";
import { onDownload } from "@/utils/utils";

export default {
  data () {
    return {
      company: null,
      userInfo: null,
      cascaderProps: {
        value: 'id',
        label: 'name',
        children: 'childList'
      },
      shopLists: [],
      currentRow: {},
      filterText: '',
      fileList: [],
      fileName: '',
      uploadDialog: false,
      pager: {
        count: 0,
        page: 1,
        rows: 10
      },
      isChecked: false,
      outerVisible: false,
      innerVisible: false,
      dialogTitle: '',
      dialog: {
        name: null,
        sex: null,
        mobile: null,
        email: null,
        comment: null,
        shop: null,
        id: null,
        activeName: '',
        checked: []
      },
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }, { validator: validatePhone, trigger: 'blur' }],
        comment: [{ required: true, message: '请输入部门', trigger: 'blur' }, { trigger: 'blur' }],
        email: [{ required: true, message: '请输入邮箱', trigger: 'blur' }, { trigger: 'blur' }],
        shop: [{ required: true, message: '请选择企业', trigger: 'blur' }],
      },
      shopOptions: [{}],
      childList: [],
      allShopList: [],
      searchData: {
        searchItem: [
          {
            label: '姓名',
            value: 'name',
            type: 'commonInput',
            dateType: 'month',
            placeholder: '请输入'
          },
          {
            label: '手机号',
            value: 'mobile',
            type: 'commonInput',
            placeholder: '请输入'
          },
          {
            label: '状态',
            value: 'status',
            type: 'commonSelect',
            placeholder: '',
            options: []
          }
        ],
        searchBtn: {
          queryBtn: true,
          resetBtn: true
        },
        params: {
          name: '',
          shopIdList: [],
          status: '',
          mobile: '',
          comment: ''
        }
      },
      columnData: [
        { prop: 'companyName', label: '企业名称' },
        { prop: 'shopNo', label: '企业ID', width: 120 },
        { prop: 'shopName', label: '店铺名称', width: '140' },
        { prop: 'name', label: '姓名' },
        { prop: 'mobile', label: '电话' },
        { prop: 'email', label: '邮箱' },
        { prop: 'managerName', label: '类型' },
        { prop: 'comment', label: '部门' },
        { prop: 'sexName', label: '性别' },
        { prop: 'createTime', label: '创建时间' },
        { prop: 'stateName', label: '状态' },
        {
          prop: 'operate',
          label: '操作',
          width: 220,
          type: {
            remove: true,
            compile: true,
            accredit: true,
            promotion: true,
            dimission: true,
          }
        }
      ],
      tableData: []
    }
  },
  computed: {
    ...mapState(['shopCache'])
  },
  components: {
    DSearch,
    DLable,
    DPaging
  },
  created () {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (this.userInfo.type == 3) {
      this.searchData.searchItem.unshift(
        {
          label: '部门',
          value: 'comment',
          type: 'commonInput',
          placeholder: '请输入'
        },
      )
    } else {
      this.searchData.searchItem.unshift(
        {
          label: '选择企业',
          value: 'shopIdList',
          type: 'linkageSelect',
          dateType: 'month',
          placeholder: '',
          options: [],
          optionProps: {
            value: 'id',
            label: 'name',
            children: 'childList'
          }
        },
      )
    }
    this.getCompany()
    this.init()
  },
  mounted () { },
  methods: {
    getCompany () {
      let params = {
        id: this.userInfo.id
      }
      this.$api.mallApi.getCompanyApi(params).then(res => {
        if (res.code == 200) {
          this.company = res.data
        }
      })
    },
    filterShop (val) {
      if (val) {
        this.childList.forEach(item => {
          item.childList = item.childList.filter(shop => shop.name.includes(val))
        })
      } else {
        this.childList = JSON.parse(JSON.stringify(this.allShopList))
      }
    },
    handleChangeUpload (file) {
      this.fileName = file.name
    },
    beforeUpload (file) {
      const isExcel = file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      if (!isExcel) {
        this.fileName = ''
        this.$message.error('上传文件只能是 Excel 格式!');
      }
      return isExcel;
    },
    handleClickDowload () {
      let api = this.userInfo.type == 3 ? 'exportCompanyEmployeeApi' : 'exportCompanyManagerApi'
      this.$api.mallApi[api]().then((res) => {
        let { headers, data } = res;
        onDownload(headers, data);
      })
    },
    uploadFile ({ file }) {
      let formData = new FormData();
      formData.append('file', file);
      let api = this.userInfo.type == 3 ? 'importCompanyEmployeeApi' : 'importCompanyManagerApi'
      this.$api.mallApi[api](formData).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.msg)
          this.uploadDialog = false
          this.getShopPersonnelList()
        } else {
          this.fileName = ''
          this.$refs.upload.clearFiles()
          this.$message.error(res.msg)
        }
      }).catch(err => {
        this.fileName = ''
        this.$refs.upload.clearFiles()
      })
    },
    submitUpload () {
      this.$refs.upload.submit()
    },
    ...mapActions(['setShopCache']),
    /**
     * 搜索按钮点击
     **/
    searchEvent () {
      this.getShopPersonnelList()
    },
    /**
     * 重置按钮点击
     **/
    resetEvent () {
      this.pager.pageNum = 1;
      this.pager.pageSize = 10;
      this.pager.count = 0;
      this.isChecked = true;
      this.searchData.params.name = '';
      this.searchData.params.shopIdList = [];
      this.searchData.params.status = '';
      this.searchData.params.mobile = '';
      this.searchData.params.comment = '';
      this.getItemList()
    },

    // 显示选择店铺弹窗
    selectShopEvent () {
      if (this.dialogTitle.includes('编辑')) { return }
      this.filterText = ''
      this.childList = JSON.parse(JSON.stringify(this.allShopList))
      this.innerVisible = true
    },
    /**
     * 编辑
     **/
    compile (column) {
      this.dialog.name = column.name
      this.dialog.sex = column.sex
      this.dialog.mobile = column.mobile
      this.dialog.email = column.email
      this.dialog.comment = column.comment
      this.dialog.activeName = column.floor
      this.dialog.shop = column.shopName
      this.dialog.id = column.id
      this.dialog.checked = [column.shopId]
      this.selectShop(column.shopName)
      this.dialogTitle = '编辑'
      this.outerVisible = true
    },
    /**
     * 新建
     **/
    addShopStaff () {
      this.dialog.name = null
      this.dialog.sex = null
      this.dialog.mobile = null
      this.dialog.email = null
      this.dialog.comment = null
      this.dialog.shop = null
      this.dialog.id = null
      this.dialog.activeName = '全部'
      this.dialog.checked = []
      this.dialogTitle = '新建'
      this.outerVisible = true
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    exportShop () {
      this.fileList = []
      this.fileName = ''
      this.uploadDialog = true
    },

    /**
     * 授权按钮
     **/
    accredit (column) {
      this.$router.push({
        path: '/mallManage/mall/mainMall/authority',
        query: { user_id: column.id }
      })
      this.setShopCache({
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        name: this.searchData.params.name,
        shop: this.searchData.params.shopIdList,
        isDimission: this.isChecked ? 1 : 0
      })
    },
    /**
     * 升值/降职
     * flag 为 top 升值
     * flag 为 reduce 降职
     **/
    positionChange (column, flag) {
      this.$confirm('是否确认此操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const obj = {
            id: column.id,
            shopId: column.shopId,
            state: flag === 'top' ? 1 : 0
          }
          this.$api.shopapi.updatePosition(obj).then(({ code, data, msg }) => {
            if (code === 200) {
              this.$message.success(msg)
              this.getShopPersonnelList()
            } else {
              this.$message.error(msg)
            }
          })
        })
        .catch(() => {

        })
    },
    /**
     * dialog确定
     **/
    confirm () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.dialogTitle === '新建') {
            const obj = {
              shopId: this.userInfo.type == 3 ? this.company.shopId : this.dialog.checked[0],
              name: this.dialog.name,
              sex: this.dialog.sex,
              mobile: this.dialog.mobile,
              email: this.dialog.email,
              comment: this.dialog.comment,
            }
            this.addAndUpdate(obj, 'add')
          } else {
            const obj = {
              id: this.dialog.id,
              shopId: this.userInfo.type == 3 ? this.company.shopId : this.dialog.checked[0],
              name: this.dialog.name,
              sex: this.dialog.sex,
              mobile: this.dialog.mobile,
              email: this.dialog.email,
              comment: this.dialog.comment,
            }
            this.addAndUpdate(obj, 'edit')
          }
        } else {
          return false
        }
      })
    },
    /**
     * inner-dialog确定 所属店铺回显
     **/
    innerDialogConfirm () {
      this.childList.map(child => {
        if (this.dialog.activeName === child.name) {
          child.childList.map(shop => {
            if (shop.id === this.dialog.checked[0]) {
              this.dialog.shop = `${shop.name.match(/\(([^)]*)\)/)[1]} ${shop.name.split('(')[0]
                }`
              this.innerVisible = false
            }
          })
        }
      })
    },
    /**
     * 删除btn
     */
    removeEvent (column) {
      this.$confirm('此操作将删除员工, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$api.shopapi
            .deleteShop(column.id)
            .then(({ code, msg, data }) => {
              if (code === 200) {
                this.getShopPersonnelList()
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
              } else {
                this.$message.error(msg)
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    /**
     * 商户增加/修改
     **/
    addAndUpdate (obj, type) {
      let api = type == 'add' ? this.userInfo.type == 3 ? 'insertEmployeeApi' : 'insertManagerApi' : 'updateUserApi'
      this.$api.mallApi[api](obj).then(({ data, code, msg }) => {
        if (code === 200) {
          this.searchEvent() // 修改成功列表更新
          this.$message.success(msg)
          this.outerVisible = false
        } else {
          this.$message.error(msg)
        }
      })
    },
    /**
     * 多选变单选
     **/
    handleChange () {
      this.dialog.checked.length > 1 && this.dialog.checked.shift()
    },

    /**
     * 获取选择店铺列表
     **/
    getShopList () {
      this.$api.busapi.getShopList().then(({ data, code }) => {
        if (code == 200) {
          let childList = JSON.parse(JSON.stringify(data.childList))
          let allChildList = []
          data.childList.forEach(child => {
            child.childList.forEach(shop => {
              allChildList.push(shop)
            })
          })
          let allShop = {
            name: '全部',
            childList: allChildList
          }
          childList.unshift(allShop)
          this.allShopList = JSON.parse(JSON.stringify(childList))
          this.childList = JSON.parse(JSON.stringify(childList))
          this.dialog.activeName =
            this.allShopList.length > 0 ? this.allShopList[0].name : null
          const child = [
            {
              id: data.id,
              name: data.name,
              childList: data.childList
            }
          ]
          this.shopLists = child
          this.searchData.searchItem.forEach(ele => {
            if (ele.type === 'linkageSelect') {
              ele.options = child
            }
          })
        }
      })
    },
    /**
     * 获取查询商户列表
     **/
    getShopPersonnelList (shop) {
      this.tableData = []
      const obj = {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        name: this.searchData.params.name,
        state: this.searchData.params.status,
        mobile: this.searchData.params.mobile,
      }
      if (this.userInfo.type == 3) {
        obj.comment = this.searchData.params.comment
      } else {
        obj.shopId = this.searchData.params.shopIdList
          ? this.searchData.params.shopIdList[
          this.searchData.params.shopIdList.length - 1
          ]
          : null
      }
      this.$api.mallApi
        .getCompanyListApi(shop || obj)
        .then(({ data, code }) => {
          if (code == 200) {
            this.pager.count = data.total
            this.setShopCache(null)
            this.tableData = data.list.map(item => {
              return {
                ...item,
                managerName: item.manager == 1 ? '管理员' : '员工',
                sexName: item.sex == 'M' ? '男' : '女',
                stateName: item.state == 1 ? '在职' : item.state == 2 ? '未激活' : '离职',
                isCompile:
                  item.state === 3 ? false : item.state !== 2,
                isAccredit: ([1,2].includes(this.userInfo.type) && item.manager == 1) || (this.userInfo.type == 3),
                isPromotion:
                  item.state === 3 ? false : item.manager !== 1,
                isDimission:
                  item.state === 3 ? false : item.state === 2 ? true : true,
                isRemove: item.state === 3 // 只有不是在职的才能删除
              }
            })
          } else {
            this.$message.error(msg)
          }
        })
    },
    /**
     * 查询请求
     **/
    getItemList () {
      this.getShopPersonnelList()
    },
    /**
     * dialog选择店铺
     **/
    selectShop () {
      this.childList.forEach(item => {
        if (item.name === this.dialog.activeName) {
          item.childList.forEach(shop => {
            if (
              this.dialog.shop.split(' ')[0] ===
              shop.name.match(/\(([^)]*)\)/)[1]
            ) {
              this.dialog.checked = [shop.id]
            }
          })
        }
      })
    },
    /**
     * 字段表查询
     */
    getSysDictList () {
      const obj = {
        dictType: 'user_status'
      }
      this.$api.shopapi.getSysDictList(obj).then(({ code, data, msg }) => {
        if (code === 200) {
          const child = data.list.map(item => ({
            ...item,
            label: item.dictLabel,
            value: item.dictValue
          }))
          this.searchData.searchItem.forEach(ele => {
            if (ele.type === 'commonSelect') {
              ele.options = child
            }
          })
        }
      })
    },
    /**
     * 初始化
     **/
    init () {
      this.getShopList()
      this.getSysDictList()
      if (this.shopCache && (this.shopCache.shop || this.shopCache.name)) {
        this.searchData.params.name = this.shopCache.name
        this.searchData.params.shopIdList = this.shopCache.shop;
        (this.pager.page = this.shopCache.pageNum),
          (this.pager.rows = this.shopCache.pageSize)
        this.shopCache.shop = this.shopCache.shop
          ? this.shopCache.shop[this.shopCache.shop.length - 1]
          : null
        this.getShopPersonnelList(this.shopCache)
      } else {
        this.getShopPersonnelList()
      }
    }
  },
  watch: {
    '$route' (to, from) {
      if (to.path === '/mallManage/mall/mainMall') {
        this.init()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.mainShop {
  background-color: #fff;
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: #fff;

    .el-table {
      flex: 1;
      overflow-y: auto;
    }
  }
}

.el-dialog__header {
  box-shadow: 0px 5px 6px -8px #555;
}

.content {
  margin-top: 10px;
  box-sizing: border-box;
  width: 100%;

  .header {
    position: relative;
    line-height: 28px;
    display: flex;
    height: 36px;

    .operation {
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;

      div {
        line-height: 28px;
      }

      .el-button {
        margin-left: 20px;
      }

      .el-checkbox {
        height: 32px;
      }
    }
  }

  .el-table {
    margin-top: 10px;
  }
}

.details {
  padding: 0 10px;

  span {
    color: red;
  }
}

.flex_item {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .item-label {
    width: 100px;
  }
}

.upload-form,
.dowload-file {
  display: flex;
  align-items: center;
  justify-content: center;
}

.floor {
  margin-top: 10px;

  .floorShop {
    width: 100%;
    min-height: 300px;
    height: 300px;
    border: 1px solid #ccc;
    padding: 20px 10px;
    box-sizing: border-box;
    font-size: 12px;
    overflow: auto;

    .el-checkbox {
      margin: 20px 0 0 15px;
      margin-top: 20px;
      width: 43%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .el-checkbox:nth-child(-n + 2) {
      margin-top: 0;
    }
  }
}

.el-select {
  width: 100%;
}

.selectTop {
  margin-top: 10px;
  display: flex;
  align-items: center;

  span {
    margin-right: 12px;
    width: 100px;
    text-align: right;
    color: #606266;
  }

  .el-input {
    flex: 1;
  }
}

.dialogMargin {
  margin-top: 20px;
}

.d-paging {
  // margin-top: 50px;
  text-align: center;
  background: #fff;
}
</style>
